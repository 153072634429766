import Vue from 'vue'
import '@fortawesome/fontawesome-free/css/all.css'

import Vuetify, {
  VRow, VCol, VCard, VContainer, VToolbar, VToolbarTitle, VCardText, VForm, VTextField, VCardActions,
  VSpacer, VBtn
} from 'vuetify/lib'

Vue.use(Vuetify, {
  components: {
    VRow,
    VCard,
    VCol,
    VContainer,
    VToolbar,
    VToolbarTitle,
    VCardText,
    VForm,
    VTextField,
    VCardActions,
    VSpacer,
    VBtn
  }
})

// import Vuetify from "vuetify";
// Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#115fa5',
        opposite: '#ffc600',
        lighter: '#156ec5', // un colore più chiaro abbinabile al primario, per i pulsanti, ad esempio
        secondary: '#f8f8f8'
      },
      dark: {
      }
    }
  }
})
