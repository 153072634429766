import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Registration"}},[_c('LoadingModal',{attrs:{"show":_vm.loading,"text":_vm.$t('FoodyTekClient registration in progress...')}}),_c(VContainer,[_c('h3',{staticClass:"text-h3 mt-4"},[_vm._v(_vm._s(_vm.$t('Fill out the information about your business')))]),_c('p',{staticClass:"text-h6 my-2 font-weight-regular",staticStyle:{"color":"#5f6368"}},[_vm._v(_vm._s(_vm.$t('You can come back later with your credentials to complete this form (but the data you insert here is not saved)')))]),_c('form-card',{staticClass:"text-left",attrs:{"title":_vm.$t('Account info')}},[_vm._l((_vm.accountInfo),function(row,index){return _c(VRow,{key:index},[_c(VCol,{staticClass:"text-overline",attrs:{"cols":"3","lg":"2"}},[_vm._v(_vm._s(row.name))]),_c(VCol,{staticClass:"align-self-center",attrs:{"cols":"9","lg":"10"}},[_vm._v(_vm._s(row.value))])],1)}),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("It's not you?")))]),_c('a',{staticClass:"ml-2",on:{"click":_vm.logoutUser}},[_vm._v(_vm._s(_vm.$t('Login with another account')))])])],1)],2),_c('validation-observer',{ref:"businessInformationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form-card',{attrs:{"title":_vm.$t('Business information')}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VSelect,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Country'),"items":_vm.validCountries,"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.country),callback:function ($$v) {_vm.$set(_vm.businessInformation, "country", $$v)},expression:"businessInformation.country"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Business name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Business name'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.businessName),callback:function ($$v) {_vm.$set(_vm.businessInformation, "businessName", $$v)},expression:"businessInformation.businessName"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Display name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Display name'),"error-messages":errors,"outlined":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VBtn,{staticClass:"mt-n1",attrs:{"icon":""},on:{"click":_vm.showDisplayNameInfo}},[_c(VIcon,[_vm._v("fa-circle-question")])],1)]},proxy:true}],null,true),model:{value:(_vm.businessInformation.displayName),callback:function ($$v) {_vm.$set(_vm.businessInformation, "displayName", $$v)},expression:"businessInformation.displayName"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('VAT ID'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('VAT ID'),"error-messages":errors,"prefix":_vm.businessInformation.country === 'it' ? 'IT' : '',"hint":"IT 01111111111","counter":_vm.businessInformation.country === 'it' ? '11' : undefined,"outlined":""},model:{value:(_vm.businessInformation.vatId),callback:function ($$v) {_vm.$set(_vm.businessInformation, "vatId", $$v)},expression:"businessInformation.vatId"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Activity tax ID'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Activity tax ID'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.taxId),callback:function ($$v) {_vm.$set(_vm.businessInformation, "taxId", $$v)},expression:"businessInformation.taxId"}})]}}],null,true)})],1),(_vm.businessInformation.country === 'it')?_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Codice destinatario","rules":"length:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":"Codice destinatario","hint":"Richiesto per la fatturazione elettronica in Italia. Se non si dispone di questo codice, inserire la PEC nel campo successivo.","error-messages":errors,"counter":"7","outlined":""},model:{value:(_vm.businessInformation.codiceDestinatario),callback:function ($$v) {_vm.$set(_vm.businessInformation, "codiceDestinatario", $$v)},expression:"businessInformation.codiceDestinatario"}})]}}],null,true)})],1):_vm._e(),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.businessInformation.country === 'it' ? 'PEC' : _vm.$t('Invoice email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.businessInformation.country === 'it' ? 'PEC' : _vm.$t('Invoice email'),"hint":_vm.businessInformation.country === 'it'
                        ? 'Obbligatorio solo se non si dispone di codice destinatario.'
                        : _vm.$t('Optional. If you have an email for invoices, enter it here.'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.invoiceEmail),callback:function ($$v) {_vm.$set(_vm.businessInformation, "invoiceEmail", $$v)},expression:"businessInformation.invoiceEmail"}})]}}],null,true)})],1)],1)],1),_c('form-card',{attrs:{"title":_vm.$t('Headquarter address')}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Address'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.line1),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "line1", $$v)},expression:"businessInformation.address.line1"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('line 2'),"hint":_vm.$t('Optional. Needed only if you have a second address line (i.e. flat, department, etc.)'),"outlined":""},model:{value:(_vm.businessInformation.address.line2),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "line2", $$v)},expression:"businessInformation.address.line2"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('City'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('City'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.city),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "city", $$v)},expression:"businessInformation.address.city"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Postal code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Postal code'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.postalCode),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "postalCode", $$v)},expression:"businessInformation.address.postalCode"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('State, country, province, or region'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [(_vm.businessInformation.country === 'it')?_c(VAutocomplete,{staticClass:"mb-n3",attrs:{"label":_vm.$t('State, country, province, or region'),"error-messages":errors,"items":_vm.italianProvinces,"outlined":""},model:{value:(_vm.businessInformation.address.state),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "state", $$v)},expression:"businessInformation.address.state"}}):_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('State, country, province, or region'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.state),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "state", $$v)},expression:"businessInformation.address.state"}})]}}],null,true)})],1)],1)],1),_c('form-card',{attrs:{"title":_vm.$t('Legal representative')}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Name'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.legalRepresentative.name),callback:function ($$v) {_vm.$set(_vm.businessInformation.legalRepresentative, "name", $$v)},expression:"businessInformation.legalRepresentative.name"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Last name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Last name'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.legalRepresentative.lastName),callback:function ($$v) {_vm.$set(_vm.businessInformation.legalRepresentative, "lastName", $$v)},expression:"businessInformation.legalRepresentative.lastName"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Birth date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Birth date'),"error-messages":errors,"type":"date","outlined":""},model:{value:(_vm.businessInformation.legalRepresentative.birthDate),callback:function ($$v) {_vm.$set(_vm.businessInformation.legalRepresentative, "birthDate", $$v)},expression:"businessInformation.legalRepresentative.birthDate"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Birthplace'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Birthplace'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.legalRepresentative.birthplace),callback:function ($$v) {_vm.$set(_vm.businessInformation.legalRepresentative, "birthplace", $$v)},expression:"businessInformation.legalRepresentative.birthplace"}})]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"text-right mb-10 mx-auto card-container"},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('Complete registration'))},on:{"click":_vm.submitBusinessInformation}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }