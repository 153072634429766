export default {
  data() {
    return {
      rules: {
        required: value => !!value || this.$t('This field is required'),
        email: value => {
          // /.+@.+\..+/.test(v)
          const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
          return pattern.test(value) || 'Email non valida'
        },
        password: value => {
          if (value.length < 8) {
            return 'La password deve contenere almeno 8 caratteri'
          }
          return true
        },
        passwordConfirmation: (value, password) => {
          if (value !== password) {
            return 'Le password non corrispondono'
          }
          return true
        }
      }
    }
  }
}