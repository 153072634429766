import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Navbar"}},[_c(VAppBar,{attrs:{"app":"","color":"primary","height":"55"}},[_c(VMenu,{attrs:{"rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"ml-auto",attrs:{"color":"white"}},'v-btn',attrs,false),on),[_c('flag',{staticClass:"mr-2",attrs:{"iso":_vm.filter(_vm.$i18n.locale)}}),_vm._v(" "+_vm._s(_vm.languageFromValue(_vm.$i18n.locale))+" ")],1)]}}])},[_c(VList,_vm._l((_vm.languages),function(lang){return _c(VListItem,{key:lang.value,attrs:{"link":""},on:{"click":function($event){return _vm.changeLang(lang.value)}}},[_c(VListItemIcon,[_c('flag',{attrs:{"iso":_vm.filter(lang.value)}})],1),_c(VListItemTitle,{staticClass:"ml-n10",domProps:{"textContent":_vm._s(lang.title)}})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }