<template src="./Registration.html"/>
<style scoped lang="scss" src="./Registration.scss"/>

<script>
import { ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import FormCard from '@/components/FormCard/FormCard'

setInteractionMode('eager')

export default {
  name: 'Registration',
  components: {
    ValidationProvider,
    ValidationObserver,
    FormCard
  },
  data () {
    return {
      credentials: {
        email: null,
        password: null,
        name: null,
        surname: null,
        mobilePhoneNumber: null
      },
      acceptInviteData: {
        mobilePhoneNumber: null
      },
      showPassword: false,

      loading: false,
      loadingModalText: null
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    }
  },
  mounted () {
    this.initAccountRegistration()
  },
  methods: {
    goToLogin () {
      this.$router.push({
        path: '/login',
        query: {
          continueRegistrationToken: this.$route.params.registrationToken
        }
      })
    },
    logout () {
      this.$store.commit('logoutUser', {
        doNotRedirect: true
      })
    },
    async initAccountRegistration () {
      try {
        const response = await this.$http.auth.get('invitations/token/' + this.$route.params.registrationToken)
        // The Registration token is valid
        this.credentials.email = response.data.email
      } catch (e) {
        // The registration token is not valid
        this.$router.push('/login')
      }
    },
    async submit () {
      try {
        const valid = await this.$refs.credentialsObserver.validate()
        if (valid) {
          if (this.isAuthenticated) await this.acceptInvite()
          else await this.registerAccount()
        }
      } catch (e) {
      }
    },
    async acceptInvite () {
      this.loadingModalText = this.$t('Accepting your invite...')
      this.loading = true
      try {
        const response = await this.$http.auth.patch(
            `invitations/token/${this.$route.params.registrationToken}/accept`,
            this.acceptInviteData
        )
        this.loading = false
        this.loginUser(response.data)
      } catch (e) {
        this.loading = false
      }
    },
    async registerAccount () {
      this.loadingModalText = this.$t('Account creation in progress...')
      this.loading = true
      try {
        const response = await this.$http.auth.post(
          'invitations/token/' + this.$route.params.registrationToken,
          this.credentials
        )
        this.loading = false

        setTimeout(() => { // wait for events to be processed
          this.loginUser(response.data)
        }, 600)
      } catch (e) {
        this.loading = false
      }
    },
    loginUser (userData) {
      this.$store.commit('loginUser', { userData })
    }
  }
}
</script>
