import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Registration"}},[_c('LoadingModal',{attrs:{"show":_vm.loading,"text":_vm.loadingModalText}}),_c(VContainer,[_c('h3',{staticClass:"text-h3 mt-4"},[_vm._v(" "+_vm._s(_vm.isAuthenticated ? _vm.$t('Accept invite') : _vm.$t('Create your account'))+" ")]),_c('p',{staticClass:"text-h6 my-2 font-weight-regular",staticStyle:{"color":"#5f6368"}},[_vm._v(_vm._s(_vm.$t('You will use this account to log into the FoodyTek Platform Dashboard')))]),_c('validation-observer',{ref:"credentialsObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form-card',{attrs:{"title":_vm.$t('Account info')}},[(_vm.isAuthenticated)?_c('div',{staticClass:"text-left mb-3"},[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":_vm.$t('Phone number'),"outlined":""},model:{value:(_vm.acceptInviteData.mobilePhoneNumber),callback:function ($$v) {_vm.$set(_vm.acceptInviteData, "mobilePhoneNumber", $$v)},expression:"acceptInviteData.mobilePhoneNumber"}})]}}],null,true)})],1),_c('p',[_c('span',{staticClass:"mr-1",domProps:{"innerHTML":_vm._s(_vm.$t('You are logged in with <b>{email}</b>. Do you want to accept the invite with this account?', { email: _vm.$store.getters.email }))}}),_c('a',{staticClass:"text-decoration-underline",on:{"click":_vm.logout}},[_vm._v(_vm._s(_vm.$t('No, log out')))])])],1):_c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Name'),"error-messages":errors,"outlined":""},model:{value:(_vm.credentials.name),callback:function ($$v) {_vm.$set(_vm.credentials, "name", $$v)},expression:"credentials.name"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Last name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":_vm.$t('Last name'),"outlined":""},model:{value:(_vm.credentials.surname),callback:function ($$v) {_vm.$set(_vm.credentials, "surname", $$v)},expression:"credentials.surname"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":"Email","outlined":""},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", $$v)},expression:"credentials.email"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":"Password","type":"password","outlined":"","type":_vm.showPassword ? 'text' : 'password',"append-icon":_vm.showPassword ? 'far fa-eye-slash' : 'far fa-eye'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Phone number","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"error-messages":errors,"label":_vm.$t('Phone number'),"outlined":""},model:{value:(_vm.credentials.mobilePhoneNumber),callback:function ($$v) {_vm.$set(_vm.credentials, "mobilePhoneNumber", $$v)},expression:"credentials.mobilePhoneNumber"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"text-left"},[_c('p',[_c('span',{staticClass:"mr-1",domProps:{"innerHTML":_vm._s(_vm.$t('Have you already registered an account in the app <b>Apollo By FoodyTek</b>?'))}}),_c('a',{staticClass:"text-decoration-underline",on:{"click":_vm.goToLogin}},[_vm._v(_vm._s(_vm.$t('Login')))])])])],1),_c('div',{staticClass:"text-left"},[_c('p',[_c('span',{staticClass:"mr-1",domProps:{"innerHTML":_vm._s(_vm.$t("Once you accept the invite, you will be able to log in with your credentials to both the <b>Apollo By FoodyTek</b> app and the FoodyTek Platform. You can save this address to access the Dashboard in the future (in most browsers you can right click on the link and press 'Bookmark Link...'):"))}}),_c('a',{attrs:{"href":"https://login.foodytek.com","target":"_blank"}},[_vm._v("https://login.foodytek.com")])])]),_c('div',{staticClass:"text-right mb-3"},[_c(VBtn,{attrs:{"color":"primary","type":"submit","disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.isAuthenticated ? _vm.$t('Accept invite') : _vm.$t('Register'))},on:{"click":_vm.submit}})],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }