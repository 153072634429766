import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"Registration"}},[_c('LoadingModal',{attrs:{"show":_vm.loading,"text":"Loading..."}}),_c(VContainer,[_c('h3',{staticClass:"text-h3 mt-4"},[_vm._v(_vm._s(_vm.$t('Fill out the information about your business')))]),_c('p',{staticClass:"text-h6 my-2 font-weight-regular",staticStyle:{"color":"#5f6368"}},[_vm._v(_vm._s(_vm.$t('You can come back later with your credentials to complete this form (but the data you insert here is not saved)')))]),_c('form-card',{staticClass:"text-left",attrs:{"title":_vm.$t('Account info')}},[_vm._l((_vm.accountInfo),function(row,index){return _c(VRow,{key:index},[_c(VCol,{staticClass:"text-overline",attrs:{"cols":"3","lg":"2"}},[_vm._v(_vm._s(row.name))]),_c(VCol,{staticClass:"align-self-center",attrs:{"cols":"9","lg":"10"}},[_vm._v(_vm._s(row.value))])],1)}),_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("It's not you?")))]),_c('a',{staticClass:"ml-2",on:{"click":_vm.logoutUser}},[_vm._v(_vm._s(_vm.$t('Login with another account')))])])],1)],2),_c('validation-observer',{ref:"businessInformationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form-card',{attrs:{"title":_vm.$t('Business information')}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Business name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Business name'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.businessName),callback:function ($$v) {_vm.$set(_vm.businessInformation, "businessName", $$v)},expression:"businessInformation.businessName"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('VAT ID'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('VAT ID'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.vatId),callback:function ($$v) {_vm.$set(_vm.businessInformation, "vatId", $$v)},expression:"businessInformation.vatId"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Activity tax ID'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Activity tax ID'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.taxId),callback:function ($$v) {_vm.$set(_vm.businessInformation, "taxId", $$v)},expression:"businessInformation.taxId"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Certified email'),"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Certified email'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.certifiedEmail),callback:function ($$v) {_vm.$set(_vm.businessInformation, "certifiedEmail", $$v)},expression:"businessInformation.certifiedEmail"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":"Codice univoco","rules":"required|length:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":"Codice destinatario","error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.codiceDestinatario),callback:function ($$v) {_vm.$set(_vm.businessInformation, "codiceDestinatario", $$v)},expression:"businessInformation.codiceDestinatario"}})]}}],null,true)})],1)],1)],1),_c('form-card',{attrs:{"title":_vm.$t('Headquarter address')}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('City'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('City'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.city),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "city", $$v)},expression:"businessInformation.address.city"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('State, country, province, or region'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.state),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "state", $$v)},expression:"businessInformation.address.state"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Address'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.line1),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "line1", $$v)},expression:"businessInformation.address.line1"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('line 2'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('line 2'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.line2),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "line2", $$v)},expression:"businessInformation.address.line2"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Postal code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VTextField,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Postal code'),"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.postalCode),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "postalCode", $$v)},expression:"businessInformation.address.postalCode"}})]}}],null,true)})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c(VSelect,{staticClass:"mb-n3",attrs:{"label":_vm.$t('Country'),"items":_vm.validCountries,"error-messages":errors,"outlined":""},model:{value:(_vm.businessInformation.address.country),callback:function ($$v) {_vm.$set(_vm.businessInformation.address, "country", $$v)},expression:"businessInformation.address.country"}})]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"text-right mb-10 mx-auto card-container"},[_c(VBtn,{attrs:{"color":"primary","disabled":_vm.loading},domProps:{"textContent":_vm._s(_vm.$t('Complete registration'))},on:{"click":_vm.submitBusinessInformation}})],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }