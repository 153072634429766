<template src="./FoodyTekClientRegistration.html"/>
<style scoped src="./FoodyTekClientRegistration.scss" lang="scss"/>

<script>
import {ValidationObserver, ValidationProvider, setInteractionMode} from 'vee-validate'
import FormCard from '@/components/FormCard/FormCard'

setInteractionMode('eager')

export default {
  name: 'FoodyTekClientRegistration',
  components: {
    ValidationProvider,
    ValidationObserver,
    FormCard
  },
  data () {
    return {
      accountInfo: [
        { name: 'Name', value: this.$store.getters.name + ' ' + this.$store.getters.surname },
        { name: 'Email', value: this.$store.getters.email }
      ],

      validCountries: [
        // Supported countries with their ISO 3166 codes. See: https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
        { text: 'Italia', value: 'it' },
        { text: 'Saudi Arabia', value: 'sa' },
        { text: 'United States', value: 'us' },
      ],

      businessInformation: {
        businessName: null,
        displayName: null,
        codiceDestinatario: null,
        invoiceEmail: null,
        // phoneNumber: null,
        taxId: null,
        vatId: null,
        country: null,
        address: {
          city: null,
          line1: null,
          line2: null,
          postalCode: null,
          state: null
        },
        legalRepresentative: {
          name: null,
          lastName: null,
          birthDate: null,
          birthplace: null,
        }
      },

      italianProvinces: [
        { value: "AG", text: "Agrigento" },
        { value: "AL", text: "Alessandria" },
        { value: "AN", text: "Ancona" },
        { value: "AO", text: "Aosta" },
        { value: "AP", text: "Ascoli piceno" },
        { value: "AQ", text: "L'aquila" },
        { value: "AR", text: "Arezzo" },
        { value: "AT", text: "Asti" },
        { value: "AV", text: "Avellino" },
        { value: "BA", text: "Bari" },
        { value: "BG", text: "Bergamo" },
        { value: "BI", text: "Biella" },
        { value: "BL", text: "Belluno" },
        { value: "BN", text: "Benevento" },
        { value: "BO", text: "Bologna" },
        { value: "BR", text: "Brindisi" },
        { value: "BS", text: "Brescia" },
        { value: "BZ", text: "Bolzano" },
        { value: "CA", text: "Cagliari" },
        { value: "CB", text: "Campobasso" },
        { value: "CE", text: "Caserta" },
        { value: "CH", text: "Chieti" },
        { value: "CL", text: "Caltanissetta" },
        { value: "CN", text: "Cuneo" },
        { value: "CO", text: "Como" },
        { value: "CR", text: "Cremona" },
        { value: "CS", text: "Cosenza" },
        { value: "CT", text: "Catania" },
        { value: "CZ", text: "Catanzaro" },
        { value: "EN", text: "Enna" },
        { value: "FC", text: "Forlì-cesena" },
        { value: "FE", text: "Ferrara" },
        { value: "FG", text: "Foggia" },
        { value: "FI", text: "Firenze" },
        { value: "FM", text: "Fermo" },
        { value: "FR", text: "Frosinone" },
        { value: "GE", text: "Genova" },
        { value: "GO", text: "Gorizia" },
        { value: "GR", text: "Grosseto" },
        { value: "IM", text: "Imperia" },
        { value: "IS", text: "Isernia" },
        { value: "KR", text: "Crotone" },
        { value: "LC", text: "Lecco" },
        { value: "LE", text: "Lecce" },
        { value: "LI", text: "Livorno" },
        { value: "LO", text: "Lodi" },
        { value: "LT", text: "Latina" },
        { value: "LU", text: "Lucca" },
        { value: "MB", text: "Monza-brianza" },
        { value: "MC", text: "Macerata" },
        { value: "ME", text: "Messina" },
        { value: "MI", text: "Milano" },
        { value: "MN", text: "Mantova" },
        { value: "MO", text: "Modena" },
        { value: "MS", text: "Massa carrara" },
        { value: "MT", text: "Matera" },
        { value: "NA", text: "Napoli" },
        { value: "NO", text: "Novara" },
        { value: "NU", text: "Nuoro" },
        { value: "OR", text: "Oristano" },
        { value: "PA", text: "Palermo" },
        { value: "PC", text: "Piacenza" },
        { value: "PD", text: "Padova" },
        { value: "PE", text: "Pescara" },
        { value: "PG", text: "Perugia" },
        { value: "PI", text: "Pisa" },
        { value: "PN", text: "Pordenone" },
        { value: "PO", text: "Prato" },
        { value: "PR", text: "Parma" },
        { value: "PT", text: "Pistoia" },
        { value: "PU", text: "Pesaro-urbino" },
        { value: "PV", text: "Pavia" },
        { value: "PZ", text: "Potenza" },
        { value: "RA", text: "Ravenna" },
        { value: "RC", text: "Reggio calabria" },
        { value: "RE", text: "Reggio emilia" },
        { value: "RG", text: "Ragusa" },
        { value: "RI", text: "Rieti" },
        { value: "RM", text: "Roma" },
        { value: "RN", text: "Rimini" },
        { value: "RO", text: "Rovigo" },
        { value: "SA", text: "Salerno" },
        { value: "SI", text: "Siena" },
        { value: "SO", text: "Sondrio" },
        { value: "SP", text: "La spezia" },
        { value: "SR", text: "Siracusa" },
        { value: "SS", text: "Sassari" },
        { value: "SV", text: "Savona" },
        { value: "TA", text: "Taranto" },
        { value: "TE", text: "Teramo" },
        { value: "TN", text: "Trento" },
        { value: "TO", text: "Torino" },
        { value: "TP", text: "Trapani" },
        { value: "TR", text: "Terni" },
        { value: "TS", text: "Trieste" },
        { value: "TV", text: "Treviso" },
        { value: "UD", text: "Udine" },
        { value: "VA", text: "Varese" },
        { value: "VB", text: "Verbania" },
        { value: "VC", text: "Vercelli" },
        { value: "VE", text: "Venezia" },
        { value: "VI", text: "Vicenza" },
        { value: "VR", text: "Verona" },
        { value: "VT", text: "Viterbo" },
        { value: "VV", text: "Vibo valentia" }
      ],

      loading: false
    }
  },
  methods: {
    showDisplayNameInfo() {
      this.$dialog.info({
        title: 'Info: ' + this.$t('Display name'),
        text: this.$t('This name will be displayed as the name of the virtual key that will be created for users on the Apollo by FoodyTek app.')
      })
    },
    async submitBusinessInformation () {
      try {
        const valid = await this.$refs.businessInformationObserver.validate()
        console.log('valid', valid)
        if (valid) {
          let data = Object.assign({}, this.businessInformation)

          if (this.businessInformation.country === 'it') {
            if (!this.businessInformation.codiceDestinatario && !this.businessInformation.invoiceEmail) {
              this.$dialog.error({
                title: 'Attenzione',
                text: 'Per favore, inserisci il codice destinatario o la PEC. Almeno uno dei due è obbligatorio per la fatturazione elettronica.',
                actions: [{ text: 'OK' }]
              })
              return
            }

            if (!data.vatId.startsWith('IT')) {
              data.vatId = 'IT' + data.vatId
            }
          }

          await this.registerFoodyTekClient(data)
        } else {
          this.$dialog.error({
            title: 'Attenzione',
            text: 'Per favore, compila tutti i campi obbligatori.',
            actions: [{ text: 'OK' }]
          })
        }
      } catch (e) {
      }
    },
    async registerFoodyTekClient (businessInfo) {
      this.loading = true
      try {
        const response = await this.$http.auth.post(
            '/ft-clients',
            businessInfo
        )
        this.loading = false
        this.$store.commit('loginUser', {
          userData: response.data,
          redirectTo: ''
        })
      } catch (e) {
        this.loading = false
      }
    },
    logoutUser () {
      this.$store.commit('logoutUser')
    }
  }
}
</script>
