<template src="./Login.html"/>

<script>
export default {
  name: 'Login',
  data () {
    return {
      step: 'home',

      emailForm: null,
      email: '',
      passwordForm: null,
      password: '',
      otp: '',

      loading: false,

      showPassword: false,

      stepperContentStyle: {
        height: '170px'
      }
    }
  },
  methods: {
    changeEmail() {
      this.step = 'home'
    },
    continueWithEmailBtn () {
      if(!this.emailForm) return
      this.continueWithEmail()
    },
    async continueWithEmail () {
      if(this.loading) return

      this.loading = true
      try {
        const {data} = await this.$http.auth.post('login/email', {
          email: this.email
        })

        if (!data.isRegistered) {
          this.$dialog.error({
            title: 'No account found',
            message: 'No account found with this email address. Please use the same email address you used to register.'
          })
          return
        }

        if (data.hasPassword) {
          this.step = 'password'
        } else {
          this.step = 'login-otp'
        }
      } finally {
        this.loading = false
      }
    },
    async goToOTPLogin () {
      try {
        await this.$http.auth.post('login/email/request-otp', {
          email: this.email
        })
      } catch (e) {
        return
      }

      this.step = 'login-otp'
    },
    async loginViaOtp () {
      if(this.loading) return

      this.loading = true

      try {
        await this.$store.dispatch('loginViaOtp', {
          authCredentials: {
            email: this.email,
            otp: this.otp
          },
          redirectTo: this.$route.query.to,
          continueRegistrationToken: this.$route.query.continueRegistrationToken
        })
      } finally {
        this.loading = false
      }
    },
    loginViaPasswordBtn () {
      if (!this.passwordForm) return
      this.loginViaPassword()
    },
    async loginViaPassword () {
      if(this.loading) return

      this.loading = true

      try {
        await this.$store.dispatch('login', {
          authCredentials: {
            email: this.email,
            password: this.password
          },
          redirectTo: this.$route.query.to,
          continueRegistrationToken: this.$route.query.continueRegistrationToken
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
